import React, { createContext, useEffect, useRef, useState } from 'react'
import { loginCustomer, getCustomerData, registerCustomer, updateCustomer, getShippingAddress, addShippingAddress, removeShippingAddress, editShippingAddress, updateDefaultShippingAddress, getCustomerOrders, activateAccount } from "@utils";

import { mockOrderHistory } from '@mockdata/mockdata'

const UserContext = createContext({
  user: '',
})

export const UserProvider = ({ children }) => {
    const menuButtonRef = useRef(null)

    const [openLoginModal, setOpenLoginModal] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [loginSuccess, setLoginSuccess] = useState(false)
    const [newlyRegistered, setNewlyRegistered] = useState(false)
    const [openUserAccountMenu, setOpenUserAccountMenu] = useState(false)
    const [user, setUser] = useState([])
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    
    const [orderHistoryData, setOrderHistoryData] = useState(mockOrderHistory)
    const [viewOrderData, setViewOrderData] = useState([])

    const [defaultShippingAddress, setDefaultShippingAddress] = useState([])
    const [shippingAddress, setShippingAddress] = useState([])
    const [mockBBBucks, setMockBBBucks] = useState(1500)
    const [customerOrders, setCustomerOrders] = useState([])
    const [token, setToken] = useState(localStorage.getItem("shopifyToken") || "");

    useEffect(() => {
        const getCX = async () => {
            setIsLoadingUser(true);
    
            try {
                const data = await getCustomerData(token);
                if (data?.data?.customer) {
                    setUser(data.data.customer);
                    setIsLoggedIn(true);
                }
            } catch (error) {
                console.error("Error fetching customer data:", error);
            } finally {
                setIsLoadingUser(false);
            }
        };
    
        if (token) {
            getCX();
        } else {
            setIsLoadingUser(false);
        }
    }, [token]);


    const register = async (firstName, lastName, email, password) => {
        return await registerCustomer(firstName, lastName, email, password);
    };

    const login = async (email, password) => {
        const res = await loginCustomer(email, password);
        
        if (res?.errors?.length > 0) {
            return res;
        } else {
            if (res.data.customerAccessTokenCreate.customerAccessToken) {
                const accessToken = res.data.customerAccessTokenCreate.customerAccessToken.accessToken;
                setToken(accessToken);
                localStorage.setItem("shopifyToken", accessToken);
                getCustomerData(accessToken).then((data) => setUser(data.data.customer));
                return true;
            } else {
                return res;
            }
        }        
    };

    const fetchCustomerOrders = async () => {
        const res = await getCustomerOrders(token);

        if (res?.data?.errors?.length > 0) {
            return res;
        } else {
            if (res?.data?.customer?.orders) {
                setCustomerOrders(res?.data?.customer?.orders?.edges)
                return true;
            } else {
                return res;
            }
        }
    };

    const fetchShippingAddress = async () => {
        const res = await getShippingAddress(token);

        if (res?.data?.errors?.length > 0) {
            return res;
        } else {
            if (res?.data?.customer?.addresses) {
                setShippingAddress(res?.data?.customer?.addresses?.edges)
                setDefaultShippingAddress(res?.data?.customer?.defaultAddress?.id)
                return true;
            } else {
                return res;
            }
        }
    };


    const createShippingAddress = async (address1, address2, city, province, country, zip, phone, company) => {
        const res = await addShippingAddress(token, address1, address2, city, province, country, zip, phone, company);


        if (res?.data?.errors?.length > 0) {
            return res;
        } else {
            if (res?.data?.customerAddressCreate?.customerAddress) {
                return await fetchShippingAddress();
                //setShippingAddress(res?.data?.customer?.addresses?.edges)
            } else {
                return res;
            }
        }
    }

    const updateShippingAddress = async (addressId, address1, address2, city, province, country, zip, phone, company) => {
        const res = await editShippingAddress(token, addressId, address1, address2, city, province, country, zip, phone, company);


        if (res?.data?.errors?.length > 0) {
            return res;
        } else {
            if (res?.data?.customerAddressUpdate?.customerAddress) {
                return await fetchShippingAddress();
            } else {
                return res;
            }
        }
    }

    const updateDefaultShipping = async (addressId) => {
        const res = await updateDefaultShippingAddress(token, addressId);


        if (res?.data?.errors?.length > 0) {
            return res;
        } else {
            if (res?.data?.customerDefaultAddressUpdate?.customer?.defaultAddress) {
                return await fetchShippingAddress();
            } else {
                return res;
            }
        }
    }

    const activateUserAccount = async (customerId, activationToken, password) => {
        const res = await activateAccount(customerId, activationToken, password);
        return res;
    }

    const deleteShippingAddress = async (addressId) => {
        const res = await removeShippingAddress(token, addressId);

        if (res?.data?.errors?.length > 0) {
            return res;
        } else {
            if (res?.data?.customerAddressDelete?.deletedCustomerAddressId) {
                return true;
            } else {
                return res;
            }
        }
    }

    const updateAccount = async (firstName, lastName, phone) => {
        const res = await updateCustomer(token, firstName, lastName, phone);
        
        if (res?.errors?.length > 0) {
            return res;
        } else {
            if (res.data.customerUpdate.customer) {
                setUser(res.data.customerUpdate.customer);
                return true;
            } else {
                return res;
            }
        }
    };

    const handleLoginSuccess = (value) => {
        setLoginSuccess(value)
        setIsLoggedIn(value)
    }

    const handleSignOut = () => {
        localStorage.removeItem("shopifyToken");
        setUser([]);
        setToken("");

        setNewlyRegistered(false)
        setLoginSuccess(false)
        setIsLoggedIn(false)
    }

    const openAccountMenu = () => setOpenUserAccountMenu(true)

    const closeAccountMenu = () => setOpenUserAccountMenu(false)

    const toggleAccountMenu = () => {
        if(menuButtonRef.current) {
        menuButtonRef.current.click()
        }
    }

    const adjustBBBucks = (value) => {
        setMockBBBucks(mockBBBucks + value)
    }

    return (
        <UserContext.Provider
        value={{
            menuButtonRef,
            isLoggedIn,
            setIsLoggedIn,
            user,
            setUser,
            openLoginModal,
            setOpenLoginModal,
            loginSuccess,
            setLoginSuccess,
            newlyRegistered,
            setNewlyRegistered,
            openUserAccountMenu,
            openAccountMenu,
            closeAccountMenu,
            toggleAccountMenu,
            handleLoginSuccess,
            
            orderHistoryData,
            viewOrderData,
            setViewOrderData,
            shippingAddress,
            
            removeShippingAddress,
            mockBBBucks,
            adjustBBBucks,
            
            register,
            login,
            updateAccount,
            handleSignOut,
            fetchShippingAddress,
            createShippingAddress,
            deleteShippingAddress,
            updateShippingAddress,
            defaultShippingAddress,
            setDefaultShippingAddress,
            updateDefaultShipping,
            isLoadingUser,
            fetchCustomerOrders,
            customerOrders,
            activateUserAccount

        }}
        >
        {children}
        </UserContext.Provider>
    );
}

export default UserContext